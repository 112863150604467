// noinspection ES6UnusedImports

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { LanguageCode } from 'src/app/enums/language'
import { ApiStatus, updateApiStatus } from 'src/app/interfaces/api-status'
import { environment } from 'src/environments/environment'
import { CombinedStateModel } from '.'
import {
  GetStatusTexts,
  HandleAuditStatusResponse,
  SwitchStatusTexts,
} from '../actions/status-texts.actions'

export interface AuditStatusText {
  StatusText: string
  CommText: string
}

export type AuditStatusTextModel = Record<LanguageCode, AuditStatusText[]>

export interface StatusTextsStateModel extends ApiStatus, AuditStatusTextModel {
  selectedStatusTexts: AuditStatusText[] | null
}

type Context = StateContext<StatusTextsStateModel>

export const defaultState: StatusTextsStateModel = {
  nl: null,
  en: null,
  hasApiError: false,
  hasConnectionError: false,
  isFetching: false,
  isInitialised: false,
  selectedStatusTexts: null,
}

@State<StatusTextsStateModel>({
  name: 'statusTexts',
  defaults: { ...defaultState },
})
@Injectable()
export class StatusTextsState {
  private headers: HttpHeaders = new HttpHeaders({
    apiKey: environment.apiKey,
  })
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store
  ) {}

  @Selector()
  static selectedStatusTexts({
    selectedStatusTexts,
  }: StatusTextsStateModel): AuditStatusText[] {
    return selectedStatusTexts
  }

  @Action(SwitchStatusTexts)
  async switchStatusTexts(ctx: Context) {
    const { participant } = this.store.snapshot() as CombinedStateModel
    const { languageCode } = participant
    const translations = ctx.getState()[languageCode]
    if (!translations) {
      return ctx.dispatch(new GetStatusTexts())
    }
    ctx.patchState({
      selectedStatusTexts: translations,
    })
  }

  @Action(GetStatusTexts)
  async getStatusTexts(ctx: Context) {
    ctx.patchState({ ...updateApiStatus({ isFetching: true }) })
    const { participant } = this.store.snapshot() as CombinedStateModel
    const { languageCode } = participant
    const url = `${
      environment.endpoints.root + environment.endpoints.statusTexts
    }?lang=${languageCode}`
    this.http.get<AuditStatusText[]>(url, { headers: this.headers }).subscribe({
      next: (data) =>
        ctx.dispatch(new HandleAuditStatusResponse(data, languageCode)),
      error: (e: any) => {
        console.warn(e.message || e)
        this.router.navigate(['/', 'login'])
      },
    })
  }

  @Action(HandleAuditStatusResponse)
  async setStatusTexts(
    ctx: Context,
    { response, lang }: HandleAuditStatusResponse
  ) {
    ctx.patchState({
      ...updateApiStatus({ isInitialised: true }),
      [lang]: response,
    })
    ctx.dispatch(new SwitchStatusTexts())
  }

  // @Action(ClearAllStates)
  // async clearState(ctx: Context) {
  //   ctx.setState({ ...defaultState });
  // }

  // ngxsOnInit(ctx: StateContext<StatusTextsStateModel>){
  //   ctx.dispatch(new SwitchStatusTexts());
  // }
}
