export interface ApiStatus {
  isFetching: boolean
  isInitialised: boolean
  hasApiError: boolean
  hasConnectionError: boolean
}

export const updateApiStatus = (prop: Partial<ApiStatus> = {}): ApiStatus => ({
  isFetching: prop.isFetching || false,
  isInitialised: prop.isInitialised || false,
  hasApiError: prop.hasApiError || false,
  hasConnectionError: prop.hasConnectionError || false,
})
