import { StudentAuditDocumentRaw } from 'src/app/models/student-audit'

export class GetStudentAuditDocuments {
  static readonly type =
    '[Student Audit Document state] GetStudentAuditDocuments'
  constructor() {}
}
export class HandleAuditsResponse {
  static readonly type = '[Student Audit Document state] HandleAuditsResponse'
  constructor(public data: StudentAuditDocumentRaw[]) {}
}

export class SetSelectedStudentAuditId {
  static readonly type =
    '[Student Audit Document state] SetSelectedStudentAudit'
  constructor(public id: string) {}
}
