import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'
import { ParticipantState } from '../store/state/participant.state'
import { Store } from '@ngxs/store'
import { SetParticipantData } from 'src/app/store/actions/participant.actions'
import { StorageService } from './storage.service'
import { AddEventLog } from '../store/actions/event-log.actions'

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private storageService: StorageService
  ) {}

  async ssoId() {
    return await this.storageService.get('ssoId')
  }

  participantId(ssoId) {
    const storedParticipantId =
      this.store.selectSnapshot(ParticipantState).participantId
    if(!storedParticipantId) return ssoId 
    const matched = storedParticipantId.match(/^(I|i)?(.{7})$/)
    if (matched && matched.length > 2) {
      return(`I${matched[2]}`)
    } else{
      return ssoId
    }
  }

  async isValidSession() {
    if (!this.ssoId()) {
      return false
    }
    const ssoValidUntil = Date.parse(
      await this.storageService.get('ssoValidUntil')
    )
    return ssoValidUntil > Date.now() + 5000 // keep margin of 5 seconds
  }

  async refreshSession() {
    const valid = await this.isValidSession()
    const ssoValidUntil = await this.storageService.get('ssoValidUntil')
    if (ssoValidUntil && !valid) {
      return false
    }
    const ssoId = await this.ssoId()

    const headers = {
      Authorization: `Bearer ${environment.contenthubApi.authorizationKey}`,
    }

    try {
      const result = await this.http
        .get(`${environment.contenthubApi.url}identity/${ssoId}`, {
          headers: headers,
        })
        .toPromise()
      if (result['status'] == 'ok') {

        this.store.dispatch(
          new SetParticipantData({
            participantId: this.participantId(ssoId),
            ssoId: ssoId,
            ssoValidUntil: result['user'].ValidTo,
            firstName: result['user'].ADFS.Firstname,
            lastName: result['user'].ADFS.Lastname,
            token: result['token'],
          })
        )
        this.store.dispatch(
          new AddEventLog({
            client_event: {
              label: 'User logged in',
              log_level: 'info',
              message: `${JSON.stringify(result)}`,
            },
          })
        )
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }
}
