<header>
  <div class="header-section">
    <div class="header-sub-section">
      <h3>{{ 'keep-alive.title' | translate }}</h3>
    </div>
  </div>
</header>
<main>
  <ion-row #content>
    <p>
      {{ 'keep-alive.description' | translate }}
    </p>
  </ion-row>
</main>
