import { Injectable } from '@angular/core'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx'
import { MenuItemOptions } from 'src/app/interfaces/content'
import { PlatformService } from './platform.service'

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  private defaultNavigationStyle = '_blank'
  constructor(
    private platform: PlatformService,
    private iab: InAppBrowser,
    private safariViewController: SafariViewController
  ) {}

  async openUrl(
    url: string,
    options: MenuItemOptions = { external: false, attributes: null }
  ) {
    if (this.platform.isWeb()) {
      try {
        window
          .open(
            url,
            options.attributes?.target
              ? options.attributes.target
              : this.defaultNavigationStyle
          )
          .focus()
      } catch (error) {
        console.error(error)
        window.location.href = url
      }
    } else {
      try {
        this.openSafariWebView(url)
      } catch (e) {
        this.openInAppBrowser(url)
      }
    }
  }

  async openSafariWebView(url: string) {
    await this.safariViewController.isAvailable()
    this.safariViewController
      .show({
        url,
        hidden: false, // default false. You can use this to load cookies etc in the background (see issue #1 for details).
        animated: false, // default true, note that 'hide' will reuse this preference (the 'Done' button will always animate though)
        transition: 'curl', // (this only works in iOS 9.1/9.2 and lower) unless animated is false you can choose from:....
        enterReaderModeIfAvailable: false, // default false
        tintColor: '#00ffff', // default is ios blue
        barColor: '#0000ff', // on iOS 10+ you can change the background color as well
        controlTintColor: '#ffffff', // on iOS 10+ you can override the default tintColor
      })
      .subscribe((result, ...other) => {
        console.log(other)
        console.log(result)
        if (result.event === 'opened') {
          console.log('opened')
        } else if (result.event === 'loaded') {
          console.log('loaded')
        } else if (result.event === 'closed') {
          console.log('closed')
        }
      })
  }

  openInAppBrowser(url: string) {
    const iab = this.iab.create(url, '_system')
    iab.on('loadstop').subscribe((e) => console.log('loadstop: ', e))
  }
}
