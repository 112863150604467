import { Component, OnInit, Input } from '@angular/core'
import { Subject } from 'rxjs'
import { Components } from '@ionic/core'

@Component({
  selector: 'mu-keepalive',
  templateUrl: './keepalive.component.html',
  styleUrls: ['./keepalive.component.scss'],
})
export class MuKeepaliveComponent implements OnInit {
  private ngUnsubscribe = new Subject()
  @Input() modal: Components.IonModal
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
