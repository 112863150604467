import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { ToastController } from '@ionic/angular'
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store'
import { ApiStatus, updateApiStatus } from 'src/app/interfaces/api-status'

import {
  StudentAudit,
  StudentAuditDocument,
  StudentAuditDocumentRaw,
} from 'src/app/models/student-audit'
import { environment } from 'src/environments/environment'
import { ClearAllStates } from '../actions/global.actions'
import {
  GetStudentAuditDocuments,
  HandleAuditsResponse,
  SetSelectedStudentAuditId,
} from '../actions/student-audit-documents.actions'
import { normalizeAuditDocument } from './normalizers/student-audit.normalizer'
import { ParticipantState } from './participant.state'
import { StudentAdmissionsState } from './student-admissions.state'
import { StudentAuditsState } from './student-audits.state'
import { AuthService } from 'src/app/services/auth.service'
import { StorageService } from 'src/app/services/storage.service'
import { LogoutService } from 'src/app/services/logout.service'



export interface StudentAuditDocumentsStateModel extends ApiStatus {
  auditDocuments: StudentAuditDocument[]
  selectedAuditId?: string
  showAuditContent: boolean
}

const defaultState: StudentAuditDocumentsStateModel = {
  auditDocuments: [],
  showAuditContent: false,
  isFetching: false,
  isInitialised: false,
  hasApiError: false,
  hasConnectionError: false,
}

@State<StudentAuditDocumentsStateModel>({
  name: 'studentAuditDocument',
  defaults: { ...defaultState },
})
@Injectable()
export class StudentAuditDocumentsState {
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private zone: NgZone,
    private toast: ToastController,
    private authService: AuthService,
    private logoutService: LogoutService,
    private storageService: StorageService
  ) {}

  private headers: HttpHeaders = new HttpHeaders({
    apiKey: environment.apiKey,
  })

  @Selector()
  static apiStatus({
    isFetching,
    isInitialised,
    hasApiError,
    hasConnectionError,
  }: StudentAuditDocumentsStateModel): ApiStatus {
    return {
      isFetching,
      isInitialised,
      hasApiError,
      hasConnectionError,
    }
  }

  @Selector()
  static auditDocuments({
    auditDocuments,
  }: StudentAuditDocumentsStateModel): StudentAuditDocument[] {
    return auditDocuments
  }

  static documentsForAudit(audit: StudentAudit) {
    return createSelector([this.auditDocuments], (auditDocuments) => {
      return auditDocuments
        .filter(({ nodeId }) => nodeId === audit.id)
        .sort((a, b) => Date.parse(a.uploadTime) - Date.parse(b.uploadTime))
    })
  }

  @Selector()
  static selectedAuditDocument({
    auditDocuments,
    selectedAuditId,
  }: StudentAuditDocumentsStateModel): StudentAuditDocument[] {
    return auditDocuments.filter(({ nodeId }) => nodeId === selectedAuditId)
  }

  @Action(GetStudentAuditDocuments)
  async getStudentAuditDocuments(
    ctx: StateContext<StudentAuditDocumentsStateModel>
  ): Promise<void> {
    if (await this.authService.isValidSession()) {
      ctx.patchState({ ...updateApiStatus({ isFetching: true }) })
      this.http
        .get<StudentAuditDocumentRaw[]>(this.constructUrl(), {
          headers: this.headers,
        })
        .subscribe({
          next: (data) => {
            ctx.dispatch(new HandleAuditsResponse(data))
          },
          error: async (e: any) => {
            const validUntil = await this.storageService.get('ssoValidUntil')
            const participantId = await this.storageService.get('participantId')
            const ssoId = await this.storageService.get('ssoId')
            this.logoutService.logout({
              label: 'Error retrieving documents',
              log_level: 'error',
              message: `${JSON.stringify(e)} (SSOId: ${ssoId}, participantId: ${participantId}, valid until ${validUntil})`,
            })
            this.router.navigate(['/', 'logout'])
          },
        })
    } else {
      this.router.navigate(['/', 'logout'])
    }
  }

  @Action(HandleAuditsResponse)
  handleAuditsResponse(
    ctx: StateContext<StudentAuditDocumentsStateModel>,
    { data }: HandleAuditsResponse
  ): void {
    const { selectedAuditId } = this.store.selectSnapshot(StudentAuditsState)
    ctx.setState((oldState) => ({
      ...oldState,
      selectedAuditId,
      auditDocuments: data.map((studentRaw) =>
        normalizeAuditDocument(studentRaw)
      ),
    }))
  }

  @Action(SetSelectedStudentAuditId)
  setSelectedStudentAuditId(
    ctx: StateContext<StudentAuditDocumentsStateModel>,
    { id }: SetSelectedStudentAuditId
  ): void {
    ctx.setState(
      (oldState): StudentAuditDocumentsStateModel => ({
        ...oldState,
        selectedAuditId: id,
        showAuditContent: true,
      })
    )
  }
  @Action(ClearAllStates)
  clearState(ctx: StateContext<StudentAuditDocumentsStateModel>): void {
    ctx.setState({ ...defaultState })
  }

  private constructUrl() {
    const { participantId } = this.store.selectSnapshot(ParticipantState)
    const { selectedGuid } = this.store.selectSnapshot(StudentAdmissionsState)

    return `${
      environment.endpoints.root +
      environment.endpoints.student +
      '/' +
      participantId +
      '/' +
      environment.endpoints.audits
    }/${selectedGuid}/${environment.endpoints.auditDocuments}`
  }
}
