import { Location } from '@angular/common'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store'
import { ApiStatus, updateApiStatus } from 'src/app/interfaces/api-status'
import {
  AuditGroup,
  AuditGroupRaw,
  StudentAudit,
  StudentAuditRaw,
  EmptyAudit,
} from 'src/app/models/student-audit'
import { environment } from 'src/environments/environment'
import { SwitchContentView } from '../actions/content.actions'
import { ClearAllStates } from '../actions/global.actions'
import { SetSelectedNodeId } from '../actions/student-admissions.actions'
import {
  GetStudentAudits,
  HandleAuditsResponse,
  ClearStudentAuditsState,
  SetSelectedStudentAuditId,
  SetShowAuditContent,
  SwitchAuditView,
} from '../actions/student-audits.actions'
import { normalizeAuditGroup } from './normalizers/student-audit.normalizer'
import { ParticipantState } from './participant.state'
import { StudentAdmissionsState } from './student-admissions.state'
import { findTask, welcomeTask } from 'src/app/models/student-audit'
import { LogoutService } from 'src/app/services/logout.service'
import { AddEventLog } from '../actions/event-log.actions'

export interface StudentAuditsStateModel extends ApiStatus {
  auditGroups: AuditGroup[]
  auditGroupsRaw: AuditGroupRaw[]
  selectedAuditId: string
  showAuditContent: boolean
  welcomeTaskID: string
}

import { ContentState, ContentStateModel } from './content.state'
import { AuthService } from 'src/app/services/auth.service'

const defaultState: StudentAuditsStateModel = {
  auditGroups: [],
  auditGroupsRaw: [],
  hasApiError: false,
  hasConnectionError: false,
  isFetching: false,
  isInitialised: false,
  selectedAuditId: '',
  showAuditContent: false,
  welcomeTaskID: '',
}

@State<StudentAuditsStateModel>({
  name: 'studentAudits',
  defaults: { ...defaultState },
})
@Injectable()
export class StudentAuditsState {
  private headers: HttpHeaders = new HttpHeaders({
    apiKey: environment.apiKey,
  })
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private zone: NgZone,
    private location: Location,
    private logoutService: LogoutService,
    private authService: AuthService
  ) {}

  @Selector()
  static apiStatus({
    isFetching,
    isInitialised,
    hasApiError,
    hasConnectionError,
  }: StudentAuditsStateModel): ApiStatus {
    return {
      isFetching,
      isInitialised,
      hasApiError,
      hasConnectionError,
    }
  }

  @Selector()
  static auditCategories({
    auditGroups,
    welcomeTaskID,
  }: StudentAuditsStateModel): Partial<StudentAuditsStateModel> {
    return { auditGroups, welcomeTaskID }
  }

  @Selector()
  static welcomeTaskID({ welcomeTaskID }: StudentAuditsStateModel): string {
    return welcomeTaskID
  }

  @Selector()
  static showAuditContent({
    showAuditContent,
  }: StudentAuditsStateModel): boolean {
    return showAuditContent
  }

  @Selector()
  static selectedAudit({
    auditGroups,
    selectedAuditId,
  }: StudentAuditsStateModel): StudentAudit {
    const [studentAudit] = auditGroups
      .flatMap<StudentAudit>(({ studentAudits }) => studentAudits)
      .filter(({ id }) => id === selectedAuditId)
    return studentAudit
  }

  static auditForSubReq(subReqName: string) {
    const subReq = environment.subRequirements[subReqName]
    return createSelector([this.allAudits], (l) => {
      return l.filter(({ subRequirement }) => subRequirement === subReq)[0]
    })
  }

  @Selector()
  static allAudits({ auditGroups }: StudentAuditsStateModel): StudentAudit[] {
    return auditGroups.flatMap<StudentAudit>(
      ({ studentAudits }) => studentAudits
    )
  }

  @Action(GetStudentAudits)
  async getStudentAudits(
    ctx: StateContext<StudentAuditsStateModel>
  ): Promise<void> {
    if (await this.authService.isValidSession()) {
      ctx.patchState({ ...updateApiStatus({ isFetching: true }) })
      this.http
        .get<AuditGroupRaw[]>(this.constructUrl(), { headers: this.headers })
        .subscribe({
          next: (data) => {
            ctx.dispatch(new HandleAuditsResponse(data))
          },
          error: (e: any) => {
            this.zone.run(() => {
              this.logoutService.logout({
                label: 'Error retrieving tasks',
                log_level: 'error',
                message: `${JSON.stringify(e)}`,
              })
            })
          },
        })
    } else {
      this.router.navigate(['/', 'logout'])
    }
  }

  @Action(HandleAuditsResponse)
  handleAuditsResponse(
    ctx: StateContext<StudentAuditsStateModel>,
    { data }: HandleAuditsResponse
  ): void {
    const { selectedNodeId, selectedGuid } = this.store.selectSnapshot(
      StudentAdmissionsState
    )
    const audits: StudentAuditRaw[] = [].concat(
      ...data.map((auditGroup) => auditGroup?.StudentAudits)
    )
    const welcome = welcomeTask(data)
    const gotoTask = findTask(
      data,
      this.store.snapshot()['Message']['gotoTaskId']
    )
    let audit: StudentAuditRaw = selectedNodeId
      ? audits.filter((adt) => adt.ID === selectedNodeId)[0]
      : gotoTask || welcome || data[0].StudentAudits[0]
    if (data[0].Categ === 'empty') {
      // Handle no Audit data for admission
      data[0].StudentAudits = [EmptyAudit]
      audit = EmptyAudit
    }
    ctx.setState((oldState) => ({
      ...oldState,
      selectedAuditId: audit.ID,
      auditGroupsRaw: data,
      welcomeTaskID: welcome?.ID,
    }))
    ctx.dispatch(new SwitchContentView())
    this.location.go('home/' + selectedGuid + '/' + audit.ID)
  }

  @Action(SetSelectedStudentAuditId)
  setSelectedStudentAuditId(
    ctx: StateContext<StudentAuditsStateModel>,
    { id }: SetSelectedStudentAuditId
  ): void {
    ctx.setState(
      (oldState): StudentAuditsStateModel => ({
        ...oldState,
        selectedAuditId: id,
        showAuditContent: true,
      })
    )
    const { selectedGuid } = this.store.selectSnapshot(StudentAdmissionsState)
    this.location.go('home/' + selectedGuid + '/' + id)
  }

  @Action(SetShowAuditContent)
  setShowAuditContent(
    ctx: StateContext<StudentAuditsStateModel>,
    { show }: SetShowAuditContent
  ): void {
    ctx.patchState({ showAuditContent: show })
  }

  @Action(SwitchAuditView)
  SwitchAuditView(
    ctx: StateContext<StudentAuditsStateModel>,
    { taskEnrichments }: SwitchAuditView
  ): void {
    if (this.store.selectSnapshot<ContentStateModel>(ContentState).isFetching) {
      return // once fetching of enrichment is done, this method will be called again, so skip it now
    }
    const { auditGroupsRaw } = ctx.getState()
    const missingEnrichmentReporter = (audits) => {
      const { studentAdmissions } = this.store.snapshot()
      const texts = audits.map((audit) => `${audit.nodeText} (${audit.id})`)
      this.store.dispatch(
        new AddEventLog({
          client_event: {
            label: "Task enrichment(s) missing",
            log_level: 'error',
            message: `Task enrichment(s): ${texts.join(
              ', '
            )} of program ${studentAdmissions.selectedEducationProgramCode} missing or unpublished.`,
          },
        })
      )
    }
    ctx.setState(
      (oldState): StudentAuditsStateModel => ({
        ...oldState,
        ...updateApiStatus({ isInitialised: true }),
        showAuditContent: false,
        auditGroups: auditGroupsRaw.map((group) =>
          normalizeAuditGroup(group, taskEnrichments, missingEnrichmentReporter)
        ),
      })
    )
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<StudentAuditsStateModel>): void {
    ctx.setState({ ...defaultState })
  }
  @Action(ClearStudentAuditsState)
  clearStudentAuditsState(ctx: StateContext<StudentAuditsStateModel>): void {
    ctx.dispatch(new SetSelectedNodeId(''))
    ctx.setState({ ...defaultState })
  }

  private constructUrl() {
    const { participantId } = this.store.selectSnapshot(ParticipantState)
    const { selectedGuid } = this.store.selectSnapshot(StudentAdmissionsState)
    return `${
      environment.endpoints.root +
      environment.endpoints.student +
      '/' +
      participantId +
      '/' +
      environment.endpoints.audits
    }/${selectedGuid}`
  }
}
