import { Injectable } from '@angular/core'
import { Action, State, StateContext, Store } from '@ngxs/store'
import {} from '../actions/content.actions'
import { AddEnabledFeatureFlags } from '../actions/feature-flag.actions'

export interface FeatureFlagModel {
  enabled: string[] | null
}

export const defaultState: FeatureFlagModel = {
  enabled: [],
}

@State<FeatureFlagModel>({
  name: 'featureFlags',
  defaults: { ...defaultState },
})
@Injectable()
export class FeatureFlagState {
  constructor(private store: Store) {}

  @Action(AddEnabledFeatureFlags)
  async addEnabledFeatureFlags(
    ctx: StateContext<FeatureFlagModel>,
    action: AddEnabledFeatureFlags
  ) {
    ctx.setState((oldState) => ({
      enabled: action.featureFlags,
    }))
  }
}
