import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import {
  TaskEnrichment,
  TaskEnrichmentData,
} from 'src/app/models/audit-enrichment'
import { ContentObject } from 'src/app/interfaces/content'
import { environment } from 'src/environments/environment'
import {
  GetTaskEnrichments,
  HandleFailureEnrichmentResponse,
  HandleTaskEnrichmentResponse,
  LocationChangeRequest,
  SwitchContentView,
} from '../actions/content.actions'
import { ClearAllStates } from '../actions/global.actions'
import { normalizeContent } from './normalizers/content.normalizer'
import {
  SetSelectedStudentAuditId,
  SetShowAuditContent,
  SwitchAuditView,
} from '../actions/student-audits.actions'
import { SetSelectedStudentAuditId as SetSelectedStudentAuditIdInDocScope } from 'src/app/store/actions/student-audit-documents.actions'
import { ApiStatus, updateApiStatus } from 'src/app/interfaces/api-status'
import { CombinedStateModel } from '.'
import { ParticipantState } from './participant.state'
import { StudentAdmissionsState } from './student-admissions.state'
import {
  SetSelectedGuid,
  SetSelectedNodeId,
} from '../actions/student-admissions.actions'
import { PlatformService } from '../../services/platform.service'

export interface ContentStateModel extends ApiStatus {
  nl: TaskEnrichmentData[] | null
  en: TaskEnrichmentData[] | null
  renderedContent: ContentObject | null
}

export const defaultState: ContentStateModel = {
  nl: null,
  en: null,
  renderedContent: null,
  hasApiError: false,
  hasConnectionError: false,
  isFetching: false,
  isInitialised: false,
}

@State<ContentStateModel>({
  name: 'content',
  defaults: { ...defaultState },
})
@Injectable()
export class ContentState {
  private headers: HttpHeaders = new HttpHeaders({
    apiKey: environment.apiKey,
  })

  @Selector()
  static apiStatus({
    isFetching,
    isInitialised,
    hasApiError,
    hasConnectionError,
  }: ContentStateModel): ApiStatus {
    return {
      isFetching,
      isInitialised,
      hasApiError,
      hasConnectionError,
    }
  }

  @Selector()
  static renderedContent({
    renderedContent,
  }: ContentStateModel): ContentObject {
    return renderedContent
  }

  constructor(
    private http: HttpClient,
    private store: Store,
    private platform: PlatformService
  ) {}

  @Action(SwitchContentView)
  switchContentView(ctx: StateContext<ContentStateModel>): void {
    const state = ctx.getState()
    const languageSpecificEnrichment = state[this.localData.languageCode]
    if (!languageSpecificEnrichment) {
      ctx.dispatch(new GetTaskEnrichments())
      return
    }
    const requestedContent = this.searchContent(state)
    ctx.setState((oldState) => ({
      ...oldState,
      renderedContent: requestedContent,
    }))
    ctx.dispatch(new SwitchAuditView(languageSpecificEnrichment))
  }

  @Action(GetTaskEnrichments)
  async getTaskEnrichments(
    ctx: StateContext<ContentStateModel>
  ): Promise<void> {
    ctx.patchState({ ...updateApiStatus({ isFetching: true }) })
    this.http
      .get<TaskEnrichment>(this.constructUrl(), { headers: this.headers })
      .subscribe({
        next: (data) => ctx.dispatch(new HandleTaskEnrichmentResponse(data)),
        error: (e) => ctx.dispatch(new HandleFailureEnrichmentResponse(e)),
      })
  }

  @Action(HandleTaskEnrichmentResponse)
  handleTaskEnrichmentResponse(
    ctx: StateContext<ContentStateModel>,
    { enrichment }: HandleTaskEnrichmentResponse
  ) {
    const { languageCode } = this.localData
    ctx.setState((oldState) => ({
      ...oldState,
      ...updateApiStatus({ isInitialised: true }),
      [languageCode]: [...enrichment.data],
    }))
    ctx.dispatch(new SwitchContentView())
  }

  @Action(HandleFailureEnrichmentResponse)
  handleFailureEnrichmentResponse(
    ctx: StateContext<ContentStateModel>,
    { response }: HandleFailureEnrichmentResponse
  ) {
    console.warn(response)
    const { languageCode } = this.localData
    if (response.status === 500) {
      ctx.setState((oldState) => ({
        ...oldState,
        ...updateApiStatus({ isInitialised: true }),
        [languageCode]: [],
      }))
    }
  }

  @Action(LocationChangeRequest)
  locationChangeRequest(
    ctx: StateContext<ContentStateModel>,
    { locationRequest }: LocationChangeRequest
  ) {
    if (locationRequest.admission) {
      // SET THE ADMISSION
      this.store.dispatch(
        new SetSelectedGuid(
          locationRequest.admission.guid,
          locationRequest.admission.status,
          locationRequest.admission.regStatus,
          locationRequest.admission.regSubStatus,
          locationRequest.admission.locked,
          locationRequest.admission.contextColor,
          locationRequest.admission.educationProgramCode,
          locationRequest.admission.studyYear,
          locationRequest.admission.academicPeriod,
          locationRequest.admission.educationProgramDescription
        )
      )
    }
    if (locationRequest.audit) {
      // SET THE AUDIT
      this.store.dispatch(new SetSelectedNodeId(locationRequest.audit))
      this.store.dispatch(new SetSelectedStudentAuditId(locationRequest.audit))
      this.store.dispatch(
        new SetSelectedStudentAuditIdInDocScope(locationRequest.audit)
      )
    }
    if (locationRequest.admission || locationRequest.audit) {
      // SHOW THE CONTENT
      this.store.dispatch(new SetShowAuditContent(true))
      this.store.dispatch(new SwitchContentView())
    }
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<ContentStateModel>): void {
    ctx.setState({ ...defaultState })
  }

  private get localData() {
    const { studentAudits, studentAdmissions, participant } =
      this.store.snapshot() as CombinedStateModel
    const { selectedAuditId } = studentAudits
    const { selectedGuid } = studentAdmissions
    const { participantId, languageCode } = participant

    return {
      selectedAuditId,
      selectedGuid,
      participantId,
      languageCode,
    }
  }

  private searchContent(state: ContentStateModel): ContentObject {
    const { languageCode, selectedAuditId } = this.localData
    return state[languageCode]
      .filter((c) => c.attributes.field_audit_node_id === selectedAuditId)
      .map(normalizeContent)[0]
  }

  private constructUrl(): string {
    const { languageCode } = this.localData
    const { participantId } = this.store.selectSnapshot(ParticipantState)
    const { selectedGuid } = this.store.selectSnapshot(StudentAdmissionsState)

    return `${
      environment.endpoints.root +
      environment.endpoints.student +
      '/' +
      participantId +
      '/' +
      environment.endpoints.audits
    }/${selectedGuid}/${environment.endpoints.enrichment}?lang=${languageCode}`
  }
}
