import { LanguageCode } from 'src/app/enums/language'
import { AuditStatusText } from '../state/status-texts.state'

export class ClearAllStates {
  static readonly type = '[Logout Button] ClearAllStates'
  constructor() {}
}

export class GetStatusTexts {
  static readonly type = '[Content State] GetStatusTexts'
  constructor() {}
}

export class HandleAuditStatusResponse {
  static readonly type = '[Status Texts State] HandleAuditStatusResponse'
  constructor(public response: AuditStatusText[], public lang: LanguageCode) {}
}
export class SwitchStatusTexts {
  static readonly type = '[Status Texts State] SwitchStatusTexts'
  constructor() {}
}
