import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  public resizeSubject: Subject<void> = this.platform.resize
  constructor(private platform: Platform) {}

  isPhone() {
    return this.platform.is('mobile') || this.platform.is('phablet')
  }

  isWeb() {
    return this.platform.is('desktop') || this.platform.is('mobileweb')
  }

  isIos() {
    return this.platform.is('ios')
  }

  platforms(){
    return this.platform.platforms()
  }
}
