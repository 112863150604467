import { Injectable } from '@angular/core'
import { Action, State, StateContext, Store, Selector } from '@ngxs/store'
import { AddEventLog, AddEventLogId } from '../actions/event-log.actions'

export interface EventLogModelClient {
  log_level: string
  label: string
  message: string | null
}

export interface EventLogModel {
  client_event: EventLogModelClient
  id?: string 
}

export const defaultState: EventLogModel = {
  client_event: {
    log_level: null,
    label: null,
    message: null, 
  }, 
  id: null,
}

@State<EventLogModel>({
  name: 'eventLog',
  defaults: { ...defaultState },
})
@Injectable()
export class EventLogState {
  constructor(private store: Store) {}

  @Action(AddEventLog)
  async addEventLog(ctx: StateContext<EventLogModel>, action: AddEventLog) {
    ctx.setState((oldState) => action.error)
  }

  @Action(AddEventLogId)
  async addEventLogId(ctx: StateContext<EventLogModel>, action: AddEventLogId) {
    ctx.setState((oldState) => ({
      ...oldState,
      id: action.id,
    }))
  }

  @Selector()
  static clientEventLog({ client_event }: EventLogModel): EventLogModelClient {
    return client_event
  }

  @Selector()
  static eventLogId({ id }: EventLogModel): string {
    return id
  }
}
