import { Injectable, NgZone } from '@angular/core'
import { Store } from '@ngxs/store'
import { StorageService } from 'src/app/services/storage.service'
import { ClearAllStates } from 'src/app/store/actions/global.actions'
import { Router } from '@angular/router'
import { AddEventLog } from 'src/app/store/actions/event-log.actions'
import { EventLogModelClient } from 'src/app/store/state/event-log.state'

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private store: Store,
    private storageService: StorageService,
    private zone: NgZone,
    private router: Router
  ) {}

  logout = async (logEvent: EventLogModelClient = null) => {
    if (logEvent && logEvent?.log_level == 'error') {
      this.store.dispatch(
        new AddEventLog({
          client_event: {
            label: logEvent.label,
            log_level: logEvent.log_level,
            message: logEvent.message,
          },
        })
      )
    }
    this.store.dispatch(new ClearAllStates())
    await this.storageService.removeStorageData()
    this.zone.run(() => {
      this.router.navigate(['/', 'login'])
    })
  }
}
