import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { Store } from '@ngxs/store'
import { StorageService } from '../services/storage.service'
import { AuthService } from 'src/app/services/auth.service'


@Injectable({ providedIn: 'root' })
export class CanActivatePublicRouteGuard implements CanActivate {
  constructor(
    public router: Router,
    private authService: AuthService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (await this.authService.isValidSession() ) {
      this.router.navigate(['/', 'home'])
      return false
    }
    return true
  }
}
