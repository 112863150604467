import { Message, MessageRaw, MessageStatus } from 'src/app/interfaces/message'

export const normalizeMessageContent = (raw: Partial<MessageRaw>): Message => {
  return {
    id: raw.MessageId,
    subject: raw.Subject,
    content: raw.Body,
    status: raw.Status,
    read: getReadMessageStatus(raw.Status),
    node_id: raw.NodeId,
    admission_id: raw.AdmissionGuid,
    send_dt: raw.DateTimeCreated,
    academicPeriod: raw.AcademicPeriod,
    studyYear: raw.StudyYear,
  }
}

export const getReadMessageStatus = (
  messageStatus: MessageStatus | string
): boolean => {
  return (
    messageStatus === MessageStatus.READ_NORMAL_PRIO ||
    messageStatus === MessageStatus.READ_HIGH_PRIO
  )
}

export const setReadMessageStatus = (
  messageStatus: MessageStatus | string
): MessageStatus => {
  return messageStatus === MessageStatus.READ_HIGH_PRIO ||
    messageStatus === MessageStatus.UNREAD_HIGH_PRIO
    ? MessageStatus.READ_HIGH_PRIO
    : MessageStatus.READ_NORMAL_PRIO
}
