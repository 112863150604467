/* eslint-disable no-unused-vars */
import { environment } from 'src/environments/environment'
export enum StatusText {
  COMPLETED = 'Completed',
  NOT_COMPLETED = 'Not Completed',
  IN_PROCESS = 'In Process',
  WAIT_FOR_UM = 'Wait for UM',
  WAIT_FOR_STUDENT = 'Wait for Student',
  WAIT_FOR_STUDENT_COMPLETED = 'Wait for Student_Completed',
  NOT_COMPLETED_COMPLETED = 'Not Completed_Completed',
  UPDATING = 'UPDATING',
}

export enum NodeType {
  OVERIG = 'O', // for the top node
  EISEN_PAKKET = 'P', // level 2 nodeType
  BEOORDELINGS_EIS = 'B', // irrelevant for FE
  SUB_EIS = 'S', // nodeType that needs to be rendered when under any P
  AANMELD_EIS = 'A', // irrelevant for FE
}

export enum NodeActor {
  AUTOMATIC = 'A',
  EMPLOYEE = 'E',
  STUDENT = 'S', // node that needs to be rendered (student action)
}

// when will I need this?
export enum NodeTaskCategory {
  STUDIELINK = 'SLI',
  ADMISSION = 'ADM',
  ENROLLEMENT = 'ENR',
}

export enum NodeTaskType {
  ACCEPT_PLACEMENT = 'A', // confirmation component
  INTERVIEW = 'I', // either datepicker component or external link
  PAYMENT = 'P', // tuition payment
  TEST = 'T', // qualtrics? => external link
  UPLOAD_FILE = 'U', // file input component
  WEB_LINK = 'W', // external ink
  HANDLING_FEE = 'H', // handling fee
}

export type StudentAuditRaw = {
  NodeInvisible4St: boolean // ??
  NodeUrgentAction: boolean // ??
  StatusText: StatusText
  Reqresult: string
  ID: string
  ParentID: string
  NodeType: NodeType
  NodeActor: NodeActor
  NodeText: string // description of node => in case of student actor, task description to be used in FE
  NodeFeedback: string | null // Feedback text
  NodeDeadlineDateTime: string | null // deadline of task, if applicable
  NodeTaskType: NodeTaskType | null
  NodeWaitStep: boolean // is grey when waiting for another task?
  NodeDocType: string | null // unique number of document that is uploaded => but why is it called type of it is an ID?
  NodeDocTypeTxt: string | null // description of the uploaded document
  NodeDocFileExt: string | null // Allowed file extensions for the upload
  NodeUrl: string | null // url of external link for the task
  Requirement: string // technical key of nodeType 'Requirement' => irrelevent
  Subrequirement: string // technical key of nodeType 'Sub Requirement' => irrelevant
  Text: string // text of (sub)requirement => irrelevant
  Weighting: string | null // irrelevant
  NodeTaskCateg: NodeTaskCategory // when will I use this?
  nodeType: NodeType
  nodeActor: NodeActor
}

export type StudentAudit = {
  statusText: StatusText
  isCompleted?: boolean
  id: string
  nodeText: string // description of node => in case of student actor, task description to be used in FE
  nodeDeadlineDateTime: string | null // deadline of task, if applicable
  nodeTaskType: NodeTaskType | null
  nodeWaitStep: boolean // is grey when waiting for another task?
  nodeDocType: string | null // unique number of document that is uploaded => but why is it called type of it is an ID?
  nodeDocTypeTxt: string | null // description of the uploaded document
  nodeDocFileExt: string | null // Allowed file extensions for the upload
  nodeTaskCategory?: string // Task Category
  nodeFeedback: string | null // Feedback provided by UM
  nodeUrl: string | null // url of external link for the task
  subRequirement: string | null // identifier of task type
}

export type StudentAuditDocumentRaw = {
  NodeID: string
  NodeDocType: string
  NodeDocTypeText: string
  DocumentID: string
  FileName: string
  FileNameOriginal?: string
  UploadTime: string
  UploadBy: string
  Authenticated?: string
  AuthenticatedBy: string
  AuthenticatedDate: string
  AuthenticationID: string
  Locked: boolean
  Irrelevant: boolean
  StudyID: string
  StudyYear: string
  AcademicPeriod: string
  FileSize: string
  Remarks?: string
}

export type StudentAuditDocument = {
  id: string
  nodeId: string
  name: string
  originalName?: string
  uploadTime: string
  uploadBy: string
  authenticated?: string
  authenticatedDate?: string
  locked: boolean
  fileSize: number
}

export type AuditGroupRaw = {
  Categ: string
  CategoryText: string
  StudentAudits: StudentAuditRaw[]
}

export type AuditGroup = {
  categ: string
  studentAudits: StudentAudit[]
  isExpanded?: boolean
}

export type SelectedStudentAudit = {
  categoryText: string
  id: string
}

export const EmptyAudit: StudentAuditRaw = {
  ID: 'XXXX',
  NodeInvisible4St: false,
  NodeUrgentAction: false,
  StatusText: StatusText.WAIT_FOR_UM,
  Reqresult: '',
  ParentID: '',
  NodeType: NodeType.EISEN_PAKKET,
  NodeActor: NodeActor.AUTOMATIC,
  NodeText: '',
  NodeFeedback: null,
  NodeDeadlineDateTime: null,
  NodeTaskType: null,
  NodeWaitStep: false,
  NodeDocType: null,
  NodeDocTypeTxt: null,
  NodeDocFileExt: null,
  NodeUrl: null,
  Requirement: '',
  Subrequirement: '',
  Text: '',
  Weighting: null,
  NodeTaskCateg: NodeTaskCategory.ADMISSION,
  nodeType: NodeType.EISEN_PAKKET,
  nodeActor: NodeActor.AUTOMATIC,
}

export function taskType(subreqId) {
  const res = Object.entries(environment.subRequirements).filter(
    (e) => e[1] === subreqId
  )[0]
  if (Array.isArray(res)) {
    return res[0]
  } else {
    return undefined
  }
}

export function combinedStatus(task1: StudentAudit, task2: StudentAudit) {
  if (oneTaskIs(StatusText.COMPLETED)) {
    return StatusText.COMPLETED
  }
  if (oneTaskIs(StatusText.IN_PROCESS)) {
    return StatusText.IN_PROCESS
  }
  if (oneTaskIs(StatusText.NOT_COMPLETED)) {
    return StatusText.NOT_COMPLETED
  }
  if (oneTaskIs(StatusText.WAIT_FOR_STUDENT)) {
    return StatusText.WAIT_FOR_STUDENT
  }
  return StatusText.WAIT_FOR_UM

  function oneTaskIs(status) {
    return task1.statusText === status || task2.statusText === status
  }
}

export function welcomeTask(taskGroups) {
  return findTask(taskGroups, environment.subRequirements.welcome)
}

export function findTask(taskGroups, id) {
  return taskGroups
    .flatMap((group) => group.StudentAudits)
    .filter((a) => {
      return a.Subrequirement === id
    })[0]
}
