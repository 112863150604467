// noinspection ES6UnusedImports

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { ApiStatus, updateApiStatus } from 'src/app/interfaces/api-status'
import { environment } from 'src/environments/environment'
import { CombinedStateModel } from '.'
import {
  GetMenuEnrichment,
  HandleMenuEnrichmentResponse,
  SwitchMenu,
} from '../actions/content.actions'
import { normalizeMenuContent } from './normalizers/content.normalizer'
import {
  MenuEnrichment,
  MenuEnrichmentData,
} from 'src/app/models/audit-enrichment'
import { MenuObject } from 'src/app/interfaces/content'

export interface MenuStateModel extends ApiStatus {
  nl: MenuEnrichmentData[] | null
  en: MenuEnrichmentData[] | null
  selectedMenu: MenuObject[]
}

export const defaultState: MenuStateModel = {
  nl: null,
  en: null,
  hasApiError: false,
  hasConnectionError: false,
  isFetching: false,
  isInitialised: false,
  selectedMenu: null,
}

@State<MenuStateModel>({
  name: 'menu',
  defaults: { ...defaultState },
})
@Injectable()
export class MenuState {
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store
  ) {}
  private headers: HttpHeaders = new HttpHeaders({
    apiKey: environment.apiKey,
  })

  @Selector()
  static selectedMenu({ selectedMenu }: MenuStateModel): MenuObject[] {
    return selectedMenu
  }

  // @Action(ClearAllStates)
  // async clearState(ctx: StateContext<MenuStateModel>) {
  //   ctx.setState({ ...defaultState });
  // }

  private static constructUrl(languageCode: string): string {
    return `${environment.endpoints.root}${environment.endpoints.menu}?lang=${languageCode}`
  }

  @Action(SwitchMenu)
  async switchMenu(ctx: StateContext<MenuStateModel>) {
    const { participant } = this.store.snapshot() as CombinedStateModel
    const { languageCode } = participant
    const translations = ctx.getState()[languageCode]?.map(normalizeMenuContent)
    if (!translations) {
      return ctx.dispatch(new GetMenuEnrichment())
    }
    ctx.patchState({
      selectedMenu: translations,
    })
  }

  @Action(GetMenuEnrichment)
  async GetMenuEnrichment(ctx: StateContext<MenuStateModel>): Promise<void> {
    const { participant } = this.store.snapshot() as CombinedStateModel
    const { languageCode } = participant
    ctx.patchState({ ...updateApiStatus({ isFetching: true }) })
    this.http
      .get<MenuEnrichment>(MenuState.constructUrl(languageCode), {
        headers: this.headers,
      })
      .subscribe({
        next: (data) => ctx.dispatch(new HandleMenuEnrichmentResponse(data)),
        error: (e) => console.warn(e),
      })
  }

  @Action(HandleMenuEnrichmentResponse)
  handleMenuEnrichmentResponse(
    ctx: StateContext<MenuStateModel>,
    { enrichment }: HandleMenuEnrichmentResponse
  ) {
    const { participant } = this.store.snapshot() as CombinedStateModel
    const { languageCode } = participant
    ctx.setState((oldState) => ({
      ...oldState,
      ...updateApiStatus({ isInitialised: true }),
      [languageCode]: enrichment.data,
    }))
    ctx.dispatch(new SwitchMenu())
  }

  // ngxsOnInit(ctx: StateContext<MenuStateModel>){
  //   ctx.dispatch(new SwitchMenu());
  // }
}
