/* eslint-disable no-unused-vars */
export interface Message {
  id: string
  subject: string
  content: string
  read?: boolean
  status: MessageStatus | string
  node_id?: string
  admission_id: string
  studyYear: string
  academicPeriod: string
  send_dt: string
}

export enum MessageStatus {
  UNREAD_NORMAL_PRIO = '1',
  READ_NORMAL_PRIO = '2',
  UNREAD_HIGH_PRIO = '3',
  READ_HIGH_PRIO = '4',
}

export interface MessageRaw {
  MessageBoxType: string
  MessageId: string
  DateTimeCreated: string
  StudentId: string
  AdmissionGuid: string
  NodeId: string
  StudyYear: string
  AcademicPeriod: string
  Status: MessageStatus | string
  StatusText: string
  DateTimeStatusChanged: string
  Subject: string
  Body: string
}
