import { LanguageCode } from 'src/app/enums/language'
import { ParticipantStateModel } from '../state/participant.state'

export class InitialiseParticipantData {
  static readonly type = '[route guards] InitialiseParticipantData'
  constructor() {}
}
export class SetParticipantData {
  static readonly type = '[Login component] SetParticipantData'
  constructor(public participantData: Partial<ParticipantStateModel>) {}
}

export class SetLanguageCode {
  static readonly type = '[Top Menu component] SetLanguage'
  constructor(public languageCode: LanguageCode) {}
}

export class SetStudentId {
  static readonly type = '[Participant state] SetStudentId'
  constructor(public studentId: string) {}
}
