import { MissingTranslationHandler } from '@ngx-translate/core'
import Store from '@ngxs/store'
import { AuditTitle, TaskEnrichmentData } from 'src/app/models/audit-enrichment'
import {
  StudentAuditRaw,
  StudentAudit,
  AuditGroup,
  AuditGroupRaw,
  StudentAuditDocumentRaw,
  StudentAuditDocument,
  StatusText,
} from 'src/app/models/student-audit'

export const normalizeAuditGroup = (
  raw: AuditGroupRaw,
  taskEnrichments: TaskEnrichmentData[],
  missingEnrichmentReporter
): AuditGroup => {
  let missingEnrichments = []
  const translatedTitles = taskEnrichments.map(({ attributes }) => ({
    id: attributes.field_audit_node_id,
    title: attributes.title,
  }))
  const normalized =  {
    categ: `category.${raw.Categ}`,
    studentAudits: raw.StudentAudits.map(normalizeAudits).map(
      translateAudit(translatedTitles, missingEnrichments)
    ),
  }
  if(missingEnrichments.length > 0) missingEnrichmentReporter(missingEnrichments)
  return normalized
}

export const translateAudit =
  (auditTitles: AuditTitle[], missingEnrichments) =>
  (audit: StudentAudit): StudentAudit => {
    const matchingAuditTitle = auditTitles.filter(
      ({ id }) => id === audit.id
    )[0]
    if (!matchingAuditTitle) {  
      missingEnrichments.push(audit)
    }
    return {
      ...audit,
      nodeText: matchingAuditTitle ? matchingAuditTitle.title : '',
    }
  }

export const normalizeAudits = (raw: StudentAuditRaw): StudentAudit => {
  return {
    statusText: raw.StatusText,
    isCompleted: raw.StatusText === StatusText.COMPLETED,
    id: raw.ID,
    nodeText: raw.NodeText,
    nodeDeadlineDateTime: raw.NodeDeadlineDateTime,
    nodeTaskType: raw.NodeTaskType,
    nodeWaitStep: raw.NodeWaitStep,
    nodeDocType: raw.NodeDocType,
    nodeDocTypeTxt: raw.NodeDocTypeTxt,
    nodeDocFileExt: raw.NodeDocFileExt,
    nodeFeedback: raw.NodeFeedback,
    nodeUrl: raw.NodeUrl,
    nodeTaskCategory: raw.NodeTaskCateg,
    subRequirement: raw.Subrequirement,
  }
}

export const normalizeAuditDocument = (
  raw: StudentAuditDocumentRaw
): StudentAuditDocument => {
  return {
    id: raw.DocumentID,
    nodeId: raw.NodeID,
    name: raw.FileName,
    originalName: raw.FileNameOriginal,
    uploadTime: raw.UploadTime,
    uploadBy: raw.UploadBy,
    locked: raw.Locked,
    authenticated: raw.Authenticated,
    authenticatedDate: raw.AuthenticatedDate,
    fileSize: parseInt(raw.FileSize),
  }
}
