/* eslint-disable camelcase */
import {
  HelpEnrichmentData,
  MenuEnrichmentData,
  TaskEnrichmentData,
} from 'src/app/models/audit-enrichment'
import {
  ContentObject,
  HelpObject,
  MenuObject,
} from 'src/app/interfaces/content'

export const normalizeContent = ({
  attributes: {
    field_after,
    field_before,
    field_survey,
    field_audit_node_id,
    title,
    langcode,
  },
}: TaskEnrichmentData): ContentObject => ({
  before: field_before ? field_before.processed : null,
  after: field_after ? field_after.processed : null,
  survey: field_survey,
  contentTitle: title,
  auditId: field_audit_node_id,
  langcode,
})

export const normalizeHelpContent = ({
  attributes: { field_text, title },
}: HelpEnrichmentData): HelpObject => ({
  title,
  content: field_text.processed,
})

export const normalizeMenuContent = ({
  attributes: {
    description,
    enabled,
    expanded,
    menu_name,
    options,
    parent,
    provider,
    title,
    url,
    weight,
  },
}: MenuEnrichmentData): MenuObject => ({
  description,
  enabled,
  expanded,
  menu_name,
  options,
  parent,
  provider,
  title,
  url,
  weight,
})
