import { StateClass } from '@ngxs/store/internals'
import { ContentState, ContentStateModel } from './content.state'
import { ParticipantState, ParticipantStateModel } from './participant.state'
import { StatusTextsState, StatusTextsStateModel } from './status-texts.state'
import { HelpState, HelpStateModel } from './help.state'
import { MenuState, MenuStateModel } from './menu.state'
import {
  StudentAdmissionsState,
  StudentAdmissionsStateModel,
} from './student-admissions.state'
import {
  StudentAuditDocumentsState,
  StudentAuditDocumentsStateModel,
} from './student-audit-documents.state'
import {
  StudentAuditsState,
  StudentAuditsStateModel,
} from './student-audits.state'
import { MessageState } from './message.state'
import { FeatureFlagState, FeatureFlagModel } from './feature-flag.state'
import { EventLogState, EventLogModel } from './event-log.state'


export type CombinedStateModel = {
  content: ContentStateModel
  participant: ParticipantStateModel
  statusTexts: StatusTextsStateModel
  help: HelpStateModel
  menu: MenuStateModel
  message: MessageState
  studentAdmissions: StudentAdmissionsStateModel
  studentAudits: StudentAuditsStateModel
  studentAuditDocuments: StudentAuditDocumentsStateModel
  featureFlags: FeatureFlagModel
  eventLog: EventLogModel
}

export const CombinedState: StateClass[] = [
  ContentState,
  ParticipantState,
  StatusTextsState,
  HelpState,
  MenuState,
  MessageState,
  StudentAdmissionsState,
  StudentAuditsState,
  StudentAuditDocumentsState,
  FeatureFlagState,
  EventLogState
]
