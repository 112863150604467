import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { LanguageCode } from 'src/app/enums/language'
import { StorageService } from '../../services/storage.service'
import { ClearAllStates } from '../actions/global.actions'
import { environment } from 'src/environments/environment'
import {
  InitialiseParticipantData,
  SetLanguageCode,
  SetParticipantData,
  SetStudentId,
} from '../actions/participant.actions'
import { SwitchHelp, SwitchMenu } from '../actions/content.actions'
import { SwitchStatusTexts } from '../actions/status-texts.actions'

export interface ParticipantStateModel {
  participantId: string
  firstName: string
  lastName: string
  languageCode: LanguageCode
  chatUrl?: string
  studentId: string
  ssoId: string
  ssoValidUntil: Date
  loginMessage: string
  token: string
}

const defaultState: ParticipantStateModel = {
  participantId: '',
  firstName: '',
  lastName: '',
  languageCode: LanguageCode.EN,
  chatUrl: environment.endpoints['chat_' + LanguageCode.EN],
  studentId: '',
  ssoId: '',
  ssoValidUntil: null,
  loginMessage: '',
  token: '',
}

@State<ParticipantStateModel>({
  name: 'participant',
  defaults: { ...defaultState },
})
@Injectable()
export class ParticipantState {
  constructor(
    private storageService: StorageService,
    private store: Store,
    private translate: TranslateService
  ) {}

  @Selector()
  static participantId({ participantId }: ParticipantStateModel): string {
    return participantId
  }

  @Selector()
  static participant(
    participant: ParticipantStateModel
  ): ParticipantStateModel {
    return participant
  }

  @Selector()
  static chatUrl({ chatUrl }: ParticipantStateModel): string {
    return chatUrl
  }

  @Selector()
  static languageCode({ languageCode }: ParticipantStateModel): LanguageCode {
    return languageCode
  }

  @Action(InitialiseParticipantData)
  async initialiseParticipantData() {
    console.log("initialiseParticipantData")
    const storageData = await this.storageService.getStorageData()
    this.store.dispatch(new SetParticipantData(storageData))
  }

  @Action(SetParticipantData)
  setParticipantData(
    ctx: StateContext<ParticipantStateModel>,
    { participantData }: SetParticipantData
  ): void {
    this.storageService.setStorageData({ ...participantData })
    ctx.setState(
      (oldState): ParticipantStateModel => ({ ...oldState, ...participantData })
    )
  }

  @Action(SetStudentId)
  setStudentId(
    ctx: StateContext<ParticipantStateModel>,
    { studentId }: SetStudentId
  ): void {
    ctx.setState(
      (oldState): ParticipantStateModel => ({
        ...oldState,
        studentId: studentId.toLowerCase(),
      })
    )
  }

  @Action(SetLanguageCode)
  setLanguage(
    ctx: StateContext<ParticipantStateModel>,
    { languageCode }: SetLanguageCode
  ): void {
    this.translate.use(languageCode)
    const chatUrl: string = environment.endpoints['chat_' + languageCode]
    ctx.setState(
      (oldState): ParticipantStateModel => ({
        ...oldState,
        languageCode,
        chatUrl,
      })
    )
    this.storageService.setLanguageCode(languageCode)

    this.store.dispatch(new SwitchHelp())
    this.store.dispatch(new SwitchMenu())
    this.store.dispatch(new SwitchStatusTexts())
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<ParticipantStateModel>, { loginMessage }:ClearAllStates) {
    ctx.setState({ ...defaultState, loginMessage })
  }
}
