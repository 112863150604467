import { TaskEnrichmentData } from 'src/app/models/audit-enrichment'
import { AuditGroupRaw } from 'src/app/models/student-audit'
export class GetStudentAudits {
  static readonly type = '[Student Audit Groups component] GetStudentAudits'
  constructor() {}
}

export class SetSelectedStudentAuditId {
  static readonly type = '[Student Audits state] SetSelectedStudentAudit'
  constructor(public id: string) {}
}

export class SetShowAuditContent {
  static readonly type = '[Student Audits state] SetShowAuditContent'
  constructor(public show: boolean) {}
}

export class SwitchAuditView {
  static readonly type = '[Content state] SwitchAuditView'
  constructor(public taskEnrichments: TaskEnrichmentData[]) {}
}

export class HandleAuditsResponse {
  static readonly type = '[Student Audits State] HandleAuditsResponse'
  constructor(public data: AuditGroupRaw[], public id?: string) {}
}

export class ClearStudentAuditsState {
  static readonly type = '[Student Audits State] ClearState'
  constructor() {}
}
