import { EventLogModel } from "../state/event-log.state"
export class AddEventLog {
  static readonly type = '[Error State] AddError'
  constructor(public error: EventLogModel){}
}

export class AddEventLogId {
  static readonly type = '[Error State] AddErrorId'
  constructor(public id: string){}
}
