import { Location } from '@angular/common'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { ApiStatus, updateApiStatus } from 'src/app/interfaces/api-status'
import {
  StudentAdmission,
  StudentAdmissionRaw,
} from 'src/app/models/student-admission'
import { environment } from 'src/environments/environment'
import { ClearAllStates } from '../actions/global.actions'
import {
  GetStudentAdmissions,
  HandleAdmissionsResponse,
  SetSelectedGuid,
  SetSelectedNodeId,
} from '../actions/student-admissions.actions'
import { normalizeAdmission } from './normalizers/student-admission.normalizer'
import { ParticipantState } from './participant.state'
import { SetStudentId } from '../actions/participant.actions'
import { LogoutService } from 'src/app/services/logout.service'
import { AuthService } from 'src/app/services/auth.service'
import { StorageService } from 'src/app/services/storage.service'

export interface StudentAdmissionsStateModel extends ApiStatus {
  selectedGuid: string
  selectedNodeId?: string
  selectedAdmissionLocked: boolean
  selectedRegStatus: string
  selectedRegSubStatus: string
  selectedEducationProgramCode: string
  selectedStudyYear: string
  selectedAcademicPeriod: string
  selectedEducationProgramDescription: string
  studentAdmissionsRaw: StudentAdmissionRaw[]
}

const defaultState: StudentAdmissionsStateModel = {
  hasApiError: false,
  hasConnectionError: false,
  isFetching: false,
  isInitialised: false,
  selectedGuid: '',
  selectedNodeId: '',
  selectedRegStatus: '0',
  selectedRegSubStatus: '0',
  selectedAdmissionLocked: false,
  selectedEducationProgramCode: '',
  selectedStudyYear: '',
  selectedAcademicPeriod: '',
  selectedEducationProgramDescription: '',
  studentAdmissionsRaw: [],
}

@State<StudentAdmissionsStateModel>({
  name: 'studentAdmissions',
  defaults: { ...defaultState },
})
@Injectable()
export class StudentAdmissionsState {
  private headers: HttpHeaders = new HttpHeaders({
    apiKey: environment.apiKey,
  })

  constructor(
    private store: Store,
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private logoutService: LogoutService,
    private authService: AuthService,
    private storageService: StorageService
  ) {}

  @Selector()
  static apiStatus({
    hasApiError,
    hasConnectionError,
    isFetching,
    isInitialised,
  }: StudentAdmissionsStateModel): ApiStatus {
    return {
      hasApiError,
      hasConnectionError,
      isFetching,
      isInitialised,
    }
  }

  @Selector()
  static selectedAdmissionDetails({
    selectedRegStatus,
    selectedRegSubStatus,
    selectedAdmissionLocked,
    selectedEducationProgramCode,
    selectedAcademicPeriod,
    selectedStudyYear,
    selectedEducationProgramDescription,
  }: StudentAdmissionsStateModel): any {
    return {
      selectedRegStatus,
      selectedRegSubStatus,
      selectedAdmissionLocked,
      selectedEducationProgramCode,
      selectedAcademicPeriod,
      selectedStudyYear,
      selectedEducationProgramDescription,
    }
  }

  @Selector()
  static selectedGuid({ selectedGuid }: StudentAdmissionsStateModel): string {
    return selectedGuid
  }

  @Selector()
  static selectedNodeId({
    selectedNodeId,
  }: StudentAdmissionsStateModel): string {
    return selectedNodeId
  }

  @Selector()
  static selectedRegStatus({
    selectedRegStatus,
    selectedRegSubStatus,
  }: StudentAdmissionsStateModel): { regStatus: string; regSubStatus: string } {
    return { regStatus: selectedRegStatus, regSubStatus: selectedRegSubStatus }
  }

  @Selector()
  static selectedAdmissionLocked({
    selectedAdmissionLocked,
  }: StudentAdmissionsStateModel): boolean {
    return selectedAdmissionLocked
  }

  @Selector()
  static studentAdmissions({
    studentAdmissionsRaw,
  }: StudentAdmissionsStateModel): StudentAdmission[] {
    return studentAdmissionsRaw.map((s) => normalizeAdmission(s))
  }

  @Action(SetSelectedNodeId)
  async setSelectedNodeId(
    ctx: StateContext<StudentAdmissionsStateModel>,
    { id }
  ): Promise<void> {
    ctx.patchState({ selectedNodeId: id })
  }

  @Action(GetStudentAdmissions)
  async getStudentAdmissions(
    ctx: StateContext<StudentAdmissionsStateModel>,
    { guid }
  ): // participantId?: string
  Promise<void> {
    ctx.patchState({
      ...updateApiStatus({ isFetching: true }),
    })
    const isValid = await this.authService.isValidSession()
    if (isValid) {
      this.http
        .get<StudentAdmissionRaw[]>(await this.constructUrl(), {
          headers: this.headers,
        })
        .subscribe({
          next: (data) =>
            ctx.dispatch(new HandleAdmissionsResponse(data, guid)),
          error: async (e: any) => {
            const validUntil = await this.storageService.get('ssoValidUntil')
            const participantId = await this.storageService.get('participantId')
            const ssoId = await this.storageService.get('ssoId')
            this.logoutService.logout({
              label: 'Error retrieving admissions',
              log_level: 'error',
              message: `${JSON.stringify(e)} (SSOId: ${ssoId}, participantId: ${participantId}, valid until ${validUntil})`,
            })
            this.router.navigate(['/', 'logout'])
          },
        })
    } else {
      this.router.navigate(['/', 'logout'])
    }
  }

  @Action(HandleAdmissionsResponse)
  handleAdmissionsResponse(
    ctx: StateContext<StudentAdmissionsStateModel>,
    { data, guid }: HandleAdmissionsResponse
  ): void {
    const compare = (a: StudentAdmissionRaw, b: StudentAdmissionRaw) => {
      if (b.RegStatus === '2' && a.RegStatus != '2') {
        return 1
      } else {
        return a.FirstStartDate < b.FirstStartDate ? 1 : -1
      }
    }

    const admissions = data
      .filter((a) => a.NewFramework)
      .sort((a, b) => compare(a, b))
    const studentAdmission = guid
      ? data.filter((admission) => admission.Guid === guid)[0]
      : admissions[0]
    const module = window.location.pathname.split('/')[1]
    if (!studentAdmission) {
      this.router.navigate(['/', 'no-admission'])
    }
    ctx.setState(() => ({
      ...updateApiStatus({ isInitialised: true }),
      studentAdmissionsRaw: admissions,
      selectedGuid: studentAdmission.Guid,
      selectedRegStatus: studentAdmission.RegStatus,
      selectedRegSubStatus: studentAdmission.RegSubStatus,
      selectedAdmissionLocked: studentAdmission.AdmissionLock,
      selectedEducationProgramCode: studentAdmission.EducationProgramCode,
      selectedStudyYear: studentAdmission.StudyYear,
      selectedAcademicPeriod: studentAdmission.AcademicPeriod,
      selectedEducationProgramDescription:
        studentAdmission.EducationProgramDescription,
    }))
    this.store.dispatch(new SetStudentId(studentAdmission.ParticipantID))
    this.location.go(`${module}/` + studentAdmission.Guid)
  }

  @Action(SetSelectedGuid)
  setSelectedGuid(
    ctx: StateContext<StudentAdmissionsStateModel>,
    action: SetSelectedGuid
  ): void {
    console.log('action', action)
    ctx.setState((oldState) => ({
      ...oldState,
      selectedGuid: action.guid,
      selectedRegStatus: action.regStatus,
      selectedRegSubStatus: action.regSubStatus,
      selectedAdmissionLocked: action.locked,
      selectedEducationProgramCode: action.educationProgramCode,
      selectedStudyYear: action.studyYear,
      selectedAcademicPeriod: action.academicPeriod,
      selectedEducationProgramDescription: action.educationProgramDescription,
    }))
    this.location.go('home/' + action.guid)
  }

  @Action(ClearAllStates)
  clearState(ctx: StateContext<StudentAdmissionsStateModel>): void {
    ctx.setState({ ...defaultState })
  }

  async constructUrl() {
    const id = await this.storageService.get('participantId')
    return `${
      environment.endpoints.root + environment.endpoints.student
    }/${id}/${environment.endpoints.admissions}`
  }
}
