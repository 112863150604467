import { HttpClientModule, HttpClient } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsModule } from '@ngxs/store'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { IonicStorageModule } from '@ionic/storage-angular'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { environment } from 'src/environments/environment'
import { CombinedState } from './store/state'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'
import { SurveyModule } from 'survey-angular-ui'

// TO ALLOW AHEAD OF TIME COMPILATION

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FontAwesomeModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    NgxsModule.forRoot(CombinedState, {
      developmentMode: !environment.production,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    SurveyModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SafariViewController,
    InAppBrowser
  ],
})
export class AppModule {}
