import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'

@Injectable({ providedIn: 'root' })
export class CanActivateProtectedRouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (await this.authService.refreshSession()) return true
    const module = window.location.pathname.split("/")[1]
    const guid = window.location.pathname.split("/")[2]
    let queryParams =  { module: module }
    if(guid) queryParams['guid'] = guid
    this.router.navigate(['/', 'login'],  { queryParams: queryParams })
    return false
  }
}
