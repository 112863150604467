/* eslint-disable no-unused-vars */
export enum LanguageCode {
  NL = 'nl',
  EN = 'en',
}

export enum Language {
  NL = 'Nederlands',
  EN = 'English',
}
