import { Message, MessageRaw } from 'src/app/interfaces/message'

export class GetMessages {
  static readonly type = '[Message State] GetMessages'
  constructor() {}
}
export class GetMessagesByAdmission {
  static readonly type = '[Message State] GetMessagesByAdmission'
  constructor(public admissionId: string) {}
}
export class HandleMessageEnrichmentResponse {
  static readonly type = '[Message State] HandleMessageEnrichmentResponse'
  constructor(public messageResponse: MessageRaw[]) {}
}
export class SwitchMessage {
  static readonly type = '[Message State] SwitchMessage'
  constructor(public id: string) {}
}
export class MarkMessageAsRead {
  static readonly type = '[Message State] MarkMessageAsRead'
  constructor(public message: Message) {}
}

export class ClearMessages {
  static readonly type = '[Message State] ClearMessages'
  constructor() {}
}

export class ToggleMessagesIsActive {
  static readonly type = '[Message State] ToggleMessagesIsActive'
  constructor() {}
}
export class SetGotoTaskId {
  static readonly type = '[Message State] SetGotoTaskId'
  constructor(public gotoTaskId: string) {}
}
