import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage'
import { Store } from '@ngxs/store'
import { LanguageCode } from 'src/app/enums/language'
import {
  SetLanguageCode,
  SetParticipantData,
} from '../store/actions/participant.actions'
import { ParticipantStateModel } from '../store/state/participant.state'

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(private storage: Storage, private store: Store) {}

  async initStorage() {
    this.storage.create()
  }

  async getStorageData(): Promise<Partial<ParticipantStateModel>> {
    const promises = [
      this.storage.get('lang'),
      this.storage.get('participantId'),
      this.storage.get('firstName'),
      this.storage.get('lastName'),
      this.storage.get('ssoId'),
      this.storage.get('ssoValidUntil'),
      this.storage.get('token'),
    ]
    const [languageCode, participantId, firstName, lastName, ssoId, ssoValidUntil, token] =
      await Promise.all(promises)
      return {
      languageCode,
      participantId,
      firstName,
      lastName,
      ssoId,
      ssoValidUntil,
      token,
    }
  }

  async initializeStoreData() {
    await this.storage.create()
    const { languageCode, participantId, firstName, lastName, ssoId, ssoValidUntil, token } =
      await this.getStorageData()
      const participantAction = new SetParticipantData({
      participantId,
      firstName,
      lastName,
      ssoId, 
      ssoValidUntil,
      token
    })
    const languageAction = new SetLanguageCode(languageCode || LanguageCode.EN)
    if (participantId) {
      this.store.dispatch(participantAction)
    }
    // INITIALIZES HELP/STATUSTEXT/MENU SO LESS CALLS HAPPEN
    this.store.dispatch(languageAction)
  }

  async hasValidAccessToken(): Promise<boolean> {
    const promises = [this.storage.get('participantId')]
    const [participantId] = await Promise.all(promises)
    return participantId
  }

  async set(key, val): Promise<void> {
    this.storage.set(key, val)
  }

  async get(key) {
    return(this.storage.get(key))
  }

  setStorageData({
    participantId,
    firstName,
    lastName,
    ssoId,
    ssoValidUntil,
    token
  }: Partial<ParticipantStateModel>): void {
    this.storage.set('participantId', participantId)
    this.storage.set('firstName', firstName)
    this.storage.set('lastName', lastName)
    this.storage.set('ssoId', ssoId)
    this.storage.set('ssoValidUntil', ssoValidUntil)
    this.storage.set('token', token)
  }

  removeStorageData(){
    this.storage.remove('participantId')
    this.storage.remove('firstName')
    this.storage.remove('lastName')
    this.storage.remove('ssoId'),
    this.storage.remove('ssoValidUntil')
    this.storage.remove('token')
  }

  async setLanguageCode(lang: LanguageCode): Promise<void> {
    await this.storage.set('lang', lang)
  }

  async getLanguageCode(): Promise<LanguageCode> {
    return this.storage.get('lang')
  }

  async getParticipantId() {
    return this.storage.get('participantId')
  }

  async remove(key) {
    await this.storage.remove(key);
  }

  clearStorage() {
    this.storage.clear()
  }
}
