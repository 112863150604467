import {
  StudentAdmissionRaw,
  StudentAdmission,
} from 'src/app/models/student-admission'
export const normalizeAdmission = (
  raw: StudentAdmissionRaw
): StudentAdmission => ({
  educationProgramID: raw.EducationProgramID,
  educationProgramName: raw.EducationProgramName,
  educationProgramYear: raw.StudyYear,
  educationProgramAcademicPeriod: raw.AcademicPeriod,
  educationProgramDescription: raw.EducationProgramDescription,
  guid: raw.Guid,
  regStatus: raw.RegStatus,
  regSubStatus: raw.RegSubStatus,
  regStatusText: raw.RegStatusText,
  regType: raw.RegType,
  regTypeText: raw.RegTypeText,
  admissionStatusText: raw.AdmissionStatusText,
  admissionLock: raw.AdmissionLock,
  contextColor: '' + raw.ContextualColor,
  newFramework: raw.NewFramework,
  educationProgramCode: raw.EducationProgramCode,
  studyYear: raw.StudyYear,
  academicPeriod: raw.AcademicPeriod,
})
