import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { AddEnabledFeatureFlags } from 'src/app/store/actions/feature-flag.actions'
import { FeatureFlagState } from '../store/state/feature-flag.state'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private store: Store) {}

  includes(flag) {
    return this.store.selectSnapshot(FeatureFlagState).enabled.includes(flag)
  }

  save(flags) {
    const allowedFlags = flags.filter((value) =>
      environment.featureFlags.available.includes(value)
    )
    this.store.dispatch(new AddEnabledFeatureFlags(allowedFlags))
  }

  loadEnabledFeature() {
    this.store.dispatch(
      new AddEnabledFeatureFlags(environment.featureFlags.enabled)
    )
  }
}
