import { HttpErrorResponse } from '@angular/common/http'
import {
  HelpEnrichment,
  LocationChangeData,
  MenuEnrichment,
  TaskEnrichment,
} from 'src/app/models/audit-enrichment'

export class GetTaskEnrichments {
  static readonly type = '[Content component] GetTaskEnrichments'
  constructor() {}
}

export class HandleTaskEnrichmentResponse {
  static readonly type = '[Content State] HandleTaskEnrichmentResponse'
  constructor(public enrichment: TaskEnrichment) {}
}

export class HandleFailureEnrichmentResponse {
  static readonly type = '[Content State] HandleFailureEnrichmentResponse'
  constructor(public response: HttpErrorResponse) {}
}

export class GetHelpEnrichment {
  static readonly type = '[Help State] GetHelpEnrichment'
  constructor() {}
}
export class HandleHelpEnrichmentResponse {
  static readonly type = '[Help State] HandleHelpEnrichmentResponse'
  constructor(public enrichment: HelpEnrichment) {}
}

export class SwitchHelp {
  static readonly type = '[Help State] SwitchHelp'
  constructor() {}
}

export class ToggleHelpIsActive {
  static readonly type = '[Help State] ToggleHelpIsActive'
  constructor() {}
}

export class GetMenuEnrichment {
  static readonly type = '[Menu State] GetMenuEnrichment'
  constructor() {}
}
export class HandleMenuEnrichmentResponse {
  static readonly type = '[Menu State] HandleMenuEnrichmentResponse'
  constructor(public enrichment: MenuEnrichment) {}
}
export class SwitchMenu {
  static readonly type = '[Menu State] SwitchMenu'
  constructor() {}
}
export class SwitchContentView {
  static readonly type =
    '[Content State | Language Popover | Student Audit Groups Component] SwitchContentView'
  constructor() {}
}

export class LocationChangeRequest {
  static readonly type = '[Content State] LocationChangeRequest'
  constructor(public locationRequest: LocationChangeData) {}
}
