import { StudentAdmissionRaw } from 'src/app/models/student-admission'

export class GetStudentAdmissions {
  static readonly type = '[Student Admissions component] GetStudentAdmissions'
  constructor(public guid?: string, public participantId?: string) {}
}

export class SetSelectedGuid {
  static readonly type = '[Student Admissions component] SetSelectedGuid'
  constructor(
    public guid: string,
    public regStatus: string,
    public regSubStatus: string,
    public status: string,
    public locked: boolean,
    public contextColor: string,
    public educationProgramCode: string,
    public studyYear: string,
    public academicPeriod: string,
    public educationProgramDescription: string
  ) {}
}

export class SetSelectedNodeId {
  static readonly type = '[Student Admissions component] SetSelectedNodeId'
  constructor(public id: string) {}
}

export class HandleAdmissionsResponse {
  static readonly type = '[Student Admissions state] HandleAdmissionsResponse'
  constructor(public data: StudentAdmissionRaw[], public guid?: string) {}
}
