import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'
import { LogoutService } from './logout.service'
import { EventLogModelClient } from 'src/app/store/state/event-log.state'
import { Store } from '@ngxs/store'
import { AddEventLogId } from 'src/app/store/actions/event-log.actions'
import { PlatformService } from 'src/app/services/platform.service'
import {
  ParticipantState,
  ParticipantStateModel,
} from 'src/app/store/state/participant.state'

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  logEventLastCalled = {}
  constructor(
    private http: HttpClient,
    private store: Store,
    private platformService: PlatformService
  ) {}

  surveyResultsApiheaders = (token) => ({
    Authorization: `Bearer ${token}`,
  })

  logEvent(eventLogMessage: EventLogModelClient) {
    const now = new Date().valueOf() 
    let elapsed;
    if(this.logEventLastCalled && this.logEventLastCalled[eventLogMessage.label]){
      elapsed = now - this.logEventLastCalled[eventLogMessage.label]
    } 
    if (
      elapsed > environment.throttle.logEvent || !elapsed
    ) {
      this.postEvent(eventLogMessage)
      this.logEventLastCalled[eventLogMessage.label] = new Date().valueOf()
    } else{
      console.log("Eventlog bounced", eventLogMessage)
    }
  }

  postEvent = (eventLogMessage: EventLogModelClient) => {
    const { token } = this.store.selectSnapshot(ParticipantState)
    const result = this.http
    .post<any>(
      environment.contenthubApi.url + 'log',
      {
        ...eventLogMessage,
        platform: this.platformService.platforms().join(', '),
        user_agent: navigator.userAgent,
      },
      { headers: this.surveyResultsApiheaders(token) }
    )
    .subscribe({
      next: (res) => {
        this.store.dispatch(new AddEventLogId(res.id))
      },
      error: (e: any) => {
        console.log('Error logging event: ', e.message || e)
      },
    })
    return result
  }
}
