import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { ApiStatus, updateApiStatus } from 'src/app/interfaces/api-status'
import { environment } from 'src/environments/environment'
import { ClearAllStates } from '../actions/global.actions'
import {
  GetMessages,
  HandleMessageEnrichmentResponse,
  MarkMessageAsRead,
  SwitchMessage,
  ToggleMessagesIsActive,
  ClearMessages,
  SetGotoTaskId,
} from '../actions/message.actions'
import {
  normalizeMessageContent,
  setReadMessageStatus,
} from './normalizers/message.normalizer'
import { Message, MessageRaw, MessageStatus } from 'src/app/interfaces/message'
import { ParticipantState } from './participant.state'

export interface MessageStateModel extends ApiStatus {
  messages: Message[] | null
  messageData: MessageRaw[]
  selectedMessage: Message
  isActive: boolean
  gotoTaskId: string
}

export const defaultState: MessageStateModel = {
  messages: null,
  messageData: null,
  selectedMessage: null,
  isActive: null,
  hasApiError: false,
  hasConnectionError: false,
  isFetching: false,
  isInitialised: false,
  gotoTaskId: '',
}

@State<MessageStateModel>({
  name: 'Message',
  defaults: { ...defaultState },
})
@Injectable()
export class MessageState {
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store
  ) {}

  private headers: HttpHeaders = new HttpHeaders({
    apiKey: environment.apiKey,
  })

  @Selector()
  static apiStatus({
    isFetching,
    isInitialised,
    hasApiError,
    hasConnectionError,
  }: MessageStateModel): ApiStatus {
    return {
      isFetching,
      isInitialised,
      hasApiError,
      hasConnectionError,
    }
  }

  @Selector()
  static isMessagesActive({ isActive }: MessageStateModel): boolean {
    return isActive
  }

  @Selector()
  static selectedMessage({ selectedMessage }: MessageStateModel): Message {
    return selectedMessage
  }

  @Selector()
  static messages({ messages }: MessageStateModel): Message[] {
    return messages
  }

  @Action(ToggleMessagesIsActive)
  async toggleMessagesIsActive(ctx: StateContext<MessageStateModel>) {
    ctx.setState((oldState) => ({
      ...oldState,
      isActive: !oldState.isActive,
      selectedMessage: null,
    }))
  }

  @Action(SetGotoTaskId)
  async setGotoTaksId(
    ctx: StateContext<MessageStateModel>,
    { gotoTaskId }: SetGotoTaskId
  ) {
    ctx.setState((oldState) => ({
      ...oldState,
      gotoTaskId: gotoTaskId,
    }))
  }

  @Action(SwitchMessage)
  async switchMessage(
    ctx: StateContext<MessageStateModel>,
    { id }: SwitchMessage
  ) {
    if (!id) {
      ctx.patchState({ selectedMessage: null })
    } else {
      const message: Message = ctx
        .getState()
        .messages?.filter((msg) => msg.id === id)[0]
      if (!message) {
        return ctx.dispatch(new GetMessages())
      }
      ctx.setState((oldState) => ({
        ...oldState,
        selectedMessage: message,
        messages: oldState.messages.map((messageOld: Message) => {
          const newMessage = { ...messageOld }
          if (newMessage.id === id) {
            if (
              newMessage.status === MessageStatus.UNREAD_NORMAL_PRIO ||
              newMessage.status === MessageStatus.UNREAD_HIGH_PRIO
            ) {
              ctx.dispatch(new MarkMessageAsRead(newMessage))
              newMessage.status = setReadMessageStatus(newMessage.status)
            }
            newMessage.read = true
          }
          return newMessage
        }),
        messageData: oldState.messageData.map((messageRaw: MessageRaw) => {
          const newMessageRaw = { ...messageRaw }
          if (newMessageRaw.MessageId === id) {
            newMessageRaw.Status = setReadMessageStatus(newMessageRaw.Status)
          }
          return newMessageRaw
        }),
      }))
    }
  }

  @Action(MarkMessageAsRead)
  async markMessageAsRead(
    ctx: StateContext<MessageStateModel>,
    { message }: MarkMessageAsRead
  ) {
    const status: string = setReadMessageStatus(message.status)
    this.http
      .post<any>(
        this.constructMessageUrl(message.id, status),
        {},
        { headers: this.headers }
      )
      .subscribe({
        next: (response) => {},
      })
  }

  @Action(GetMessages)
  async GetMessages(ctx: StateContext<MessageStateModel>): Promise<void> {
    ctx.patchState({ ...updateApiStatus({ isFetching: true }) })

    this.http
      .get<MessageRaw[]>(this.constructUrl(), { headers: this.headers })
      .subscribe({
        next: (data) => ctx.dispatch(new HandleMessageEnrichmentResponse(data)),
        error: (e) => {
          ctx.dispatch(new HandleMessageEnrichmentResponse([]))
        },
      })
  }

  @Action(HandleMessageEnrichmentResponse)
  handleMessageEnrichmentResponse(
    ctx: StateContext<MessageStateModel>,
    { messageResponse }: HandleMessageEnrichmentResponse
  ) {
    ctx.setState((oldState) => ({
      ...oldState,
      ...updateApiStatus({ isInitialised: true }),
      messageData: messageResponse,
      messages: messageResponse.map(normalizeMessageContent),
    }))
  }

  @Action(ClearMessages)
  async clearMessages(ctx: StateContext<MessageStateModel>) {
    ctx.setState({ ...defaultState })
  }

  @Action(ClearAllStates)
  async clearState(ctx: StateContext<MessageStateModel>) {
    ctx.setState({ ...defaultState })
  }

  private constructUrl(): string {
    const { participantId } = this.store.selectSnapshot(ParticipantState)
    // eslint-disable-next-line max-len
    return `${environment.endpoints.root}${environment.endpoints.student}/${participantId}/${environment.endpoints.message}`
  }

  private constructMessageUrl(id: string, status: string) {
    const { participantId } = this.store.selectSnapshot(ParticipantState)
    // eslint-disable-next-line max-len
    return `${environment.endpoints.root}${environment.endpoints.student}/${participantId}/${environment.endpoints.message}/${id}/${environment.endpoints.messageStatus}?status=${status}`
  }
}
